// Recupera gli ID di tutti i prodotti del listing
let allIds = [];

function getAllIds(){
  if(allIds && allIds.length > 0){
    return allIds;
  }

  $(".listItem").each(
    function() {
      let id = $(this).attr('id');
      allIds.push(id);
    }
  );

  return allIds;
}

// Recupera gli ID prodotto del singolo filtro
function getIdsByFilterValue(value) {
  let ids = [];
  $(".listItem." + value).each(
    function() {
      let id = $(this).attr('id');
      ids.push(id);
    }
  );
  return ids;
}

// Recupera gli ID prodotto del gruppo specificato in base ai filtri Checckati.
function getIdsByGroup(xpat_group) {
  let ids = [];
  let selector = xpat_group + " input:checked";
  if($(xpat_group + " input:checked").length === 0){
    ids = getAllIds();
  }else{
    $(selector).each(
      function(){
        let tmp_ids = getIdsByFilterValue($(this).val());
        $.merge(ids, tmp_ids);
      }
    )
  }
  return $.uniqueArray(ids);
}

// Recupera gli ID di prodotto esclusi quelli del gruppo passato come parametro
function getIdsByOthersGroups(this_group){
  let ids = getAllIds();
  $('.filters__item__content[data-attr!=' + this_group +']').each(function(){
    let group_name = $(this).attr('data-attr');
    let tmp_ids = getIdsByGroup('.filters__item__content[data-attr=' + group_name + ']');
    ids = $.arrayIntersect(ids, tmp_ids);
  });
  return $.uniqueArray(ids);
}

function showItems(){
  let ids = getAllIds();

  $('.filters__item__content').each(function(){
    let group_name = $(this).attr('data-attr');
    let tmp_ids = getIdsByGroup('.filters__item__content[data-attr=' + group_name + ']');

    let intersect = $.arrayIntersect(tmp_ids, ids);

    if(intersect.length !== 0){
      ids = intersect;
    }
  });
  $(".listItem").hide();
  for(let i=0; i < ids.length; i++){
    $('.listItem#' + ids[i]).show();
  }
  return ids;
}

function updateCheckbox(){
  $('.filters__item__content').each(function(){

    let group_name = $(this).attr('data-attr');
    let other_ids = getIdsByOthersGroups(group_name);

    $(this).find('input').each(function(){
      let ids_filtro = getIdsByFilterValue($(this).val());
      if($.arrayIntersect(ids_filtro, other_ids).length === 0){
        $(this).prop('disabled',true);
        $(this).prop('checked', false );
      }else{
        $(this).prop('disabled',false);
      }
    })
  });
}

function generateFiltersQueryParameter(){
  let filters = [];
  let filtersString;

  //find all active filters
  $('.filter-checkbox:checkbox:checked').each(function(){
      if($(this) && !$(this).prop('disabled')){
          filters.push($(this).val());
      }
  });

  //generate query parameter 'filters'
  if(filters && filters.length > 0){
      filtersString = "";
      filters.forEach(function (filter) {
          filtersString += filter + ",";
      });
      filtersString = "?filters=" + filtersString.slice(0, -1);
      $('#filters_reset_link').css("display", "block");
  } else {
      $('#filters_reset_link').css("display", "none");
  }

  window.history.replaceState(null, null, filtersString ? filtersString : "?");
}

function hideFilterContainerIfEmpty() {
    let visibleFilterNumber = $('.filters__item').filter((index, node) => $(node).css('display') !== 'none').length;
    if(visibleFilterNumber === 0){
        $('#product_filters_container').css("display", "none");
    }
}

function getQueryParameterByName(name, url) {
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function applyFiltersFromQueryParameter(){
  let looped = false;
  let filtersQueryParameter = getQueryParameterByName("filters", window.location.href);

  if(filtersQueryParameter){
      let filtersList = filtersQueryParameter.split(",");
      if(filtersList && filtersList.length > 0){

          filtersList.forEach(function (filter) {
              let filterInput = $('.filters input.' + filter);
              if(filterInput && !filterInput.prop('disabled')){
                  filterInput.prop('checked', true);
                  filterInput.closest('.filters__item').find('.filters__item__header').removeClass('collapsed').addClass('show');
                  filterInput.closest('.filters__item__content').removeClass('collapse').addClass('show');
              }

              showItems();
              updateCheckbox();
              looped = true;
          });
      }
      $('#filters_reset_link').css("display", "block");
  }

  if(!looped){
      showItems();
      updateCheckbox();
  }
}

function showUniqueFilters(filter){
    if($('.listItem.' + filter + '_1').length > 0){
        $('.filter-checkbox.' + filter + '_1').closest('.filters__item--unique').show();
    }
}

function initSortingButtons(){
    let $productList = $('.products-list');
    let $products = $productList.children('div');
    let $sortSelect = $('#price_order');
    let sortList = Array.prototype.sort.bind($products);

    let doSortByPrice = function (ascending) {
        sortList(function (a, b) {

            let aPrice = $(a).data("price");
            if(aPrice){
                aPrice = parseFloat(aPrice);
            }
            let bPrice = $(b).data("price");
            if(bPrice){
                bPrice = parseFloat(bPrice);
            }

            if ( aPrice < bPrice ) {
                return ascending ? -1 : 1;
            }
            if ( aPrice > bPrice ) {
                return ascending ? 1 : -1;
            }
            return 0;
        });
        $productList.append($products);
    };
    let doSortByRelevance = function () {
        sortList(function (a, b) {

            let aRelevance = $(a).data("presentation-order");
            if(aRelevance){
                aRelevance = parseInt(aRelevance);
            }
            let bRelevance = $(b).data("presentation-order");
            if(bRelevance){
                bRelevance = parseInt(bRelevance);
            }

            return aRelevance < bRelevance ? -1 : 1;
        });
        $productList.append($products);
    };

    $sortSelect.on('select2:select', function (e) {
        let data = e.params.data;
        if(data.id === "price_asc_button") {
            doSortByPrice(true);
        }else if(data.id === "price_desc_button") {
            doSortByPrice(false);
        }else if(data.id === "relevance_button") {
            doSortByRelevance();
        }
    });
}

let filters = {
  setup : function() {
    this._productFiltersSelector = 'product_filters';
    this.$productFilters = $('#'+this._productFiltersSelector );
    this.$productList = $('#product-list-container'); 
    this._headerHeight = $('.mobile-menu').outerHeight();
    this._filtersScrollTop = ($('.filters_title ').offset().top) - this._headerHeight - 10;
    this.$filtersBtnReset = $('.filters_buttons .resetFilters');
    this.$filtersActiveBox = $('#mobile_filters_active_box');
    this.$filtersTotalResult= $('.filters_total_results'); 
    this.$body= $('body');
  },

  scrollTop: function () {
    $("html, body").scrollTop(this._filtersScrollTop);
  },

  resetFilters: function (isMobile){
    $('.filters input').each(function(){
        $(this).prop('checked', false );
    });
    showItems();
    updateCheckbox();
    generateFiltersQueryParameter();

    if(isMobile){
      $('.filters__item__content').collapse('hide');
      this.$filtersActiveBox.hide();
    } else {
      this.updateMobileFiltersTotal();
    }
    
  },

  resetSingleFilterGroup: function (filterId){
    $('.filters input[data-filter=' + filterId + ']').prop('checked', false);
    $('li.filter_item[data-attr=' + filterId + ']').remove();
    $('.filters__item__content[data-attr=' + filterId + ']').collapse('hide');

    if(!$('li.filter_item').length){
      this.$filtersActiveBox.hide();
    }

    showItems();
    updateCheckbox();
    generateFiltersQueryParameter();
  },

  getMobileFiltersStructure: function (){
    let structure = [];
    $('.filters__item').each(function (){
        let $activeFilters = $(this).find(".filter-checkbox:checked");
        if($activeFilters.length){

            let values = "";
            $activeFilters.each(function (){
                values += $(this).attr('name') + ", ";
            });
            values = values.slice(0, -2);

            let filterHeader = $(this).find('.filters__item__header');
            let itemStructure = {
                filterId: filterHeader.data("attr"),
                filterLabel: filterHeader.text().trim(),
                values: values
            };

            structure.push(itemStructure);
        }
    });
    return structure;
  },

  updateMobileFiltersBox: function (){
    let structure = filters.getMobileFiltersStructure();
    if(structure && structure.length){
      let html = "";
      structure.forEach(function (item){
        let isUniqueFilter = (item.filterId === 'IS_NEW' || item.filterId ===  'IS_COMMERCIAL' || item.filterId ===  'CAN_BUY_ONLINE')? "visuallyHidden" : "";
        html += "<li class='filter_item' data-attr='" + item.filterId + "'>" +
            "       <span class='filter_category " + isUniqueFilter + "'>" + item.filterLabel + ": </span>" +
            "       <span class='filter_values'>" + (isUniqueFilter ? item.values.charAt(0).toUpperCase() + item.values.slice(1) : item.values) + "</span>" +
            "       <button type='button' class='remove-filter text-dark' data-attr='" + item.filterId + "'>" +
            "           <span class='icon' aria-hidden='true'></span>" +
            "           <span class='text visuallyHidden'>Remove Filters</span>" +
            "       </button>" +
            "   </li>"
      });
      this.$filtersActiveBox.find('ul').html(html);
      this.$filtersActiveBox.show();
    }else{
      this.$filtersActiveBox.hide();
    }
  },

    updateMobileFiltersTotal: function () {
        $('.products-list-page__content').each(function() {
            const container = $(this);
            let $count = $(container).find('#product-list-container').find('.listItem:visible').length;
            $(container).find('.filters_total_results').find('.count').html($count);
            if ($count === 1) {
                $(container).find('.filters_total_one').show();
                $(container).find('.filters_total_many').hide();
            } else {
                $(container).find('.filters_total_one').hide();
                $(container).find('.filters_total_many').show();
            }
        })
    },

  bindEvents:  function()  {

    this.$filtersBtnReset.on('click', function(){
      this.resetFilters(true);
    }.bind(this));

    //--OPEN FILTERS 
    this.$productFilters.on('shown.bs.collapse', function (e){
      if (e.target.id === this._productFiltersSelector ) {
        this.$body.addClass('filters_opened');  //filtersTotalResult, productList, comparatorContainer hidden by CSS
        this.$filtersActiveBox.hide();
        this.scrollTop();
      }
    }.bind(this));

    //-- CLOSE FILTERS
    this.$productFilters.on('hidden.bs.collapse', function (e){
      if (e.target.id === this._productFiltersSelector ) {
        this.updateMobileFiltersBox();
        this.updateMobileFiltersTotal();
        this.$body.removeClass('filters_opened');
        this.scrollTop();
      }
    }.bind(this));

    $('body').on('click', '.remove-filter', function (){
      let filterId = $(this).data('attr');
      if(filterId){
        filters.resetSingleFilterGroup(filterId);
        filters.updateMobileFiltersTotal();
      }
    });
  },

  init: function () {
    try {
      this.setup();
      this.bindEvents();
      this.updateMobileFiltersBox();
      this.updateMobileFiltersTotal();
    }
    catch (e) {
      console.error(e);
    } 
  }
};

$(document).ready(function() {
    //-- FILTER SECTION
    $('.filters input').on("click", function(){
        showItems();
        updateCheckbox();
        generateFiltersQueryParameter();
        filters.updateMobileFiltersTotal();
        let isCheckedItem = $(this).is(':checked')

        window.dataLayer = window.dataLayer || [];

        if(isCheckedItem) {
            window.dataLayer.push({
                event: 'applicazione-filtro-listing',
                filterType: $(this).data('filter') || '',
                filterValue: $(this).attr('name')  || '',
            });
        }

    });

    $('#filters_reset_link').on('click', function(){
      filters.resetFilters(false);
    }.bind(this));

    $('#filters_reset_link_mobile').on('click', function(){
          filters.resetFilters(true);
        }.bind(this));

    //-- APPLY FILTERS IN URL
    applyFiltersFromQueryParameter();

    //-- SHOW UNIQUE FILTERS IF NEEDED
    showUniqueFilters('IS_NEW');
    showUniqueFilters('IS_COMMERCIAL_MODE');
    showUniqueFilters('CAN_BUY_ONLINE');

    //-- HIDE FILTER CONTAINER IF NO FILTERS ARE VISIBLE
    hideFilterContainerIfEmpty();

    //-- INIT SORTING BUTTONS
    initSortingButtons();

    //-- FILTERS MANAGEMENT
    filters.init();
});